import React from "react"
import { Container } from "react-bootstrap"
import thanks_icon from '../../images/icons/thanks_icon.svg'

import * as styles from "./thankyouSection.module.scss"

const Thankyou = () => {
    return (
        <section className={styles.section}>
            <Container>
                <div className={styles.container}>
                    <div>
                        <img src={thanks_icon} alt='thank-you' loading='lazy' width='70px'/>
                        <h1 className='main-heading-36 text-light'>Thank You for Reaching Out!</h1>
                        <p className='text-light font-weight-normal'>One of our team representatives will get back in touch with you as soon as possible.</p>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Thankyou
