import React from "react";
import MainLayout from "../layouts/MainLayout";
import { SEO } from "../components/common";

import { DownloadGuides } from "../components/home-sections";
import Thankyou from "../components/thankyou/thankyouSection";

const ThankyouPage = () => {
  const seo = {
    title: "Thank You - Tv2u",
    metaDesc:
      "Global Software Development Outsourcing Company. Hire Tv2u offshore developers team and tech experts for web and product development.",
  };
  return (
    <MainLayout bgChanged={false}>
      <SEO title={seo.title} description={seo.metaDesc} />
      <Thankyou />
      <DownloadGuides p={true} />
    </MainLayout>
  );
};

export default ThankyouPage;
